import config from "config/analytics";

import makeTrackers from "./makeTrackers.js";

if (module.hot) {
  // Instead of worrying about analytics settings diffing on hot reload,
  // we simply reload the page. This is also be the case when one of
  // the integration's script is changed.
  module.hot.accept("config/analytics", () => {
    window.location.reload();
  });
}

let resolveAnalytics;
let analyticsReady = new Promise((resolve) => {
  resolveAnalytics = resolve;
});

if (
  !process.env.SERVER &&
  process.env.NODE_ENV === "development" &&
  process.env.FRONT_COMMERCE_WEB_DEV_ANALYTICS_WARNING_DISABLE !== "true"
) {
  if (config?.analytics?.enable && config?.analytics?.plugins?.length) {
    import("common-tags")
      .then(({ stripIndent }) => {
        const timeout = setTimeout(() => {
          console.warn(
            stripIndent`
              Analytics: It seems that you didn't start Front-Commerce's analytics system.
              This is most likely because you don't use CookieLink in your page. You can either:
              * add back "theme/modules/Cookie/CookieLink"
              * call "web/core/analytics::initAnalytics" in your own component
              * disable this warning by setting FRONT_COMMERCE_WEB_DEV_ANALYTICS_WARNING_DISABLE=true
            `
          );
        }, 1000);

        analyticsReady.then(() => clearTimeout(timeout));
      })
      .catch((e) => console.error(e));
  }
}

const initAnalytics = async (authorizations = {}) => {
  if (!process.env.SERVER && process.env.NODE_ENV !== "test") {
    if (!config.analytics || !config.analytics.enable) {
      return null;
    }

    const isLegacy =
      !config.analytics.plugins?.length &&
      config.analytics.integrations?.length;

    if (isLegacy) {
      const deprecate = await import("../utils/depd.browser").then(
        ({ default: depd }) => depd("config/analytics legacy")
      );

      const message = `Integrations are used with legacy analytics which will be removed in version 3.0.0, please see the documentation: https://developers.front-commerce.com/docs/advanced/analytics/getting-started`;
      deprecate(message);
      if (process.env.NODE_ENV !== "production") {
        console.warn(message);
      }
      return await import("./modules/analytics-legacy.js").then(
        async ({ default: AnalyticsLegacy }) => {
          const analytics = await AnalyticsLegacy(authorizations, trackEvent);
          if (analytics) {
            analytics.ready(() => resolveAnalytics(analytics));
          }
          return analytics;
        }
      );
    }
    return await import("./modules/analytics.js").then(
      async ({ default: Analytics }) => {
        const analytics = await Analytics(authorizations, trackEvent);
        if (analytics) {
          analytics.ready(() => resolveAnalytics(analytics));
        }
        return analytics;
      }
    );
  }
};

const { trackEvent, trackPage } = makeTrackers(analyticsReady);

export { trackPage, trackEvent, initAnalytics };
