// replacement du système img svg par un système de font icomoon
import React from "react";
import PropTypes from "prop-types";
import invariant from "fbjs/lib/invariant";
import classNames from "classnames";
import {
  IoIosHome,
  IoIosPerson,
  IoIosPeople,
  IoIosArrowForward,
  IoIosArrowBack,
  IoIosArrowDropright,
  IoIosArrowDropleft,
  IoIosArrowDropup,
  IoIosArrowDropdown,
  IoIosAdd,
  IoIosRemove,
  IoIosAddCircle,
  IoIosRemoveCircle,
  IoIosCloseCircle,
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosExit,
  IoIosClose,
  IoIosPhonePortrait,
  IoIosCheckmarkCircle,
  IoIosCheckmark,
  IoIosBusiness,
  IoIosLock,
  IoIosCalendar,
  IoIosCard,
  IoIosArrowRoundDown,
  IoIosShirt,
  IoIosHappy,
  IoIosCog,
  IoIosEye,
  IoMdCreate,
  IoIosTrash,
  IoIosMail,
  IoIosCamera,
  IoIosSync,
  IoIosMenu,
  IoIosList,
  IoIosRedo,
  IoIosCart,
  IoIosHeart,
  IoIosSearch,
  IoIosCash,
  IoIosCube,
  IoIosStar,
  IoIosStarHalf,
  IoIosOptions,
  IoIosDownload,
  IoIosUndo,
  IoMdShare,
  IoIosWarning,
  IoIosEyeOff,
  IoIosNotifications,
  IoIosNotificationsOff,
} from "react-icons/io";

import { FaUserCircle, FaFacebook, FaGoogle } from "react-icons/fa";
import { GiOrganigram } from "react-icons/gi";
import { VscGripper } from "react-icons/vsc";

const svgIcons = [
  "cart",
  "user",
  "search",
  "credit-card",
  "grid-1",
  "grid-4",
  "left-circle",
  "checkbox-unchecked",
  "arrow",
  "rivets",
  "close",
  "pin",
  "phone1",
  "clock",
  "envelope",
  "arrow-right",
  "caret",
  "check",
  "box",
  "address-book",
  "exit",
  "eye",
  "eye-off",
];

const keyToComponent = {
  home: IoIosHome,
  user: IoIosPerson,
  users: IoIosPeople,
  "chevron-right": IoIosArrowForward,
  "chevron-left": IoIosArrowBack,
  "chevron-up": IoIosArrowUp,
  "chevron-down": IoIosArrowDown,
  "chevron-right-circle": IoIosArrowDropright,
  "chevron-left-circle": IoIosArrowDropleft,
  "chevron-up-circle": IoIosArrowDropup,
  "chevron-down-circle": IoIosArrowDropdown,
  plus: IoIosAdd,
  minus: IoIosRemove,
  "plus-circle": IoIosAddCircle,
  "circle-minus": IoIosRemoveCircle,
  "cross-circle": IoIosCloseCircle,
  exit: IoIosExit,
  cross: IoIosClose,
  "phone-handset": IoIosPhonePortrait,
  "checkmark-circle": IoIosCheckmarkCircle,
  checkmark: IoIosCheckmark,
  store: IoIosBusiness,
  lock: IoIosLock,
  "calendar-full": IoIosCalendar,
  "credit-card": IoIosCard,
  "sort-amount-asc": IoIosArrowRoundDown,
  shirt: IoIosShirt,
  smile: IoIosHappy,
  cog: IoIosCog,
  eye: IoIosEye,
  "eye-off": IoIosEyeOff,
  pencil: IoMdCreate,
  trash: IoIosTrash,
  envelope: IoIosMail,
  camera: IoIosCamera,
  magnifier: IoIosSearch,
  sync: IoIosSync,
  menu: IoIosMenu,
  list: IoIosList,
  redo: IoIosRedo,
  cart: IoIosCart,
  heart: IoIosHeart,
  cash: IoIosCash,
  cube: IoIosCube,
  star: IoIosStar,
  "star-half": IoIosStarHalf,
  options: IoIosOptions,
  download: IoIosDownload,
  undo: IoIosUndo,
  share: IoMdShare,
  warn: IoIosWarning,
  "user-circle": FaUserCircle,
  organigram: GiOrganigram,
  gripper: VscGripper,
  facebook: FaFacebook,
  google: FaGoogle,
  notification: IoIosNotifications,
  "notification-off": IoIosNotificationsOff,
};

export const whitelist = Object.keys(keyToComponent).sort();

const wrapperComponents = {
  default: ({ children }) => {
    return children;
  },
  withAppearance: ({ children, appearance, size }) => {
    const className = classNames("icon-wrapper", {
      [`icon-wrapper--${appearance}`]: appearance,
      [`icon-wrapper--${size}`]: size,
    });
    return <div className={className}>{children}</div>;
  },
};

const Icon = ({ icon, size, title, appearance }) => {
  invariant(
    whitelist.indexOf(icon) > -1 || svgIcons.indexOf(icon) > -1,
    `"${icon}" is not a whitelisted icon`
  );

  if (process.env.NODE_ENV === "development" && title === undefined) {
    console.warn(
      "You must set a title (it can be an empty string if you don't want one) to use <Icon />."
    );
  }

  const className = classNames("icon", {
    [`icon--${size}`]: size,
    [`icon--${appearance}`]: appearance,
    [`icon-${icon}`]: icon,
  });

  const Wrapper = appearance
    ? wrapperComponents.withAppearance
    : wrapperComponents.default;

  if (svgIcons.indexOf(icon) > -1) {
    return (
      <Wrapper appearance={appearance} size={size}>
        <i className={className}></i>
      </Wrapper>
    );
  } else {
    const IconComponent = keyToComponent[icon];
    return (
      <Wrapper appearance={appearance} size={size}>
        <IconComponent title={title} className={className} />
      </Wrapper>
    );
  }
};

Icon.defaultProps = {
  size: "default",
  appearance: "default",
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf([
    "default",
    "default-block",
    "block",
    "round",
    "round-border",
    "inactive",
    "x-big",
  ]),
  size: PropTypes.oneOf(["default", "small", "mini", "big"]),
  title: PropTypes.string.isRequired,
};

export default Icon;
