import React from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";
import { OptionalLink } from "theme/components/atoms/Typography/Link/";
import Wysiwyg from "theme/modules/WysiwygV2";

const Block601 = ({ isDesktop, data }) => {
  const vimeo = data?.vimeo_link ?? null;
  const vimeo_mobile = data?.vimeo_link_mobile ?? null;
  const img_mobile = data?.image_mobile?.url ?? null;
  return (
    <div className="block-prismic block-601">
      <div className="row">
        <div className="xxs12 xs12 md5 xl4 last-md">
          {vimeo ? (
            <div className="video-ctn">
              <ReactPlayer
                url={!isDesktop && vimeo_mobile ? vimeo_mobile : vimeo}
                playing={true}
                playsinline={true}
                muted={true}
                loop={true}
                height="auto"
                width="auto"
              />
            </div>
          ) : data.image ? (
            <Image
              src={
                isDesktop
                  ? data.image.url
                  : img_mobile
                  ? img_mobile
                  : data.image.url
              }
              alt={data.image.alt ?? data.label}
              format="original"
            />
          ) : null}
        </div>
        <div className="xxs12 md7 xl8 mt-4 mt-md-0">
          <div className="row start-xxs fh">
            {data.titleWysiwyg ? (
              <div className="xxs12 md7 hero-l block-601__title">
                <OptionalLink to={data.link}>
                  <Wysiwyg content={data.titleWysiwyg} />
                </OptionalLink>
              </div>
            ) : null}
            <p className="xxs12 md5 mt-5 mt-md-auto p1 mb-0 mr-7 mr-md-0">
              <OptionalLink to={data.link} appearance="text">
                {data.label} <i className="icon icon-arrow"></i>
              </OptionalLink>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block601);
