import React, { useState } from "react";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Carousel from "theme/components/organisms/Carousel/Carousel";
import ProductItem from "theme/modules/ProductView/ProductItem";

const Block511 = ({ isDesktop, title, data }) => {
  const [tabOpen, setTabOpen] = useState(0);

  const products = data.products.filter((product) => product);
  if (!products.length) {
    return null;
  }

  const { product, image, image_mobile } = products[tabOpen];

  return (
    <div className="block-prismic block-511">
      <div className="block-511__product flex">
        <div className="block-511__product__infos xxs12 sm4">
          <ProductItem key={product.sku} product={product} {...product} />
        </div>
        <div className="block-511__product__img xxs12 sm8">
          {image ? (
            <Image
              src={isDesktop ? image.url : image_mobile.url}
              alt={image.alt ?? product.fullName}
              format="original"
            />
          ) : null}
        </div>
      </div>
      <div className="flex">
        <div className="block-511__nav-ctn flex nowrap top-xxs start-xxs mt-4">
          <Carousel>
            {data.modele ? (
              <span className="block-511__brand mr-2">{data.modele}</span>
            ) : null}
            <div className="block-511__nav flex start-xxs">
              {data.products.map((el, i) => (
                <div
                  key={`producttab${i}`}
                  className={`block-511__nav-link${
                    i === tabOpen ? " active" : ""
                  }`}
                  onClick={() => setTabOpen(i)}
                >
                  {el.product.name}
                </div>
              ))}
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block511);
