class DoorService {
  constructor([state, setState]) {
    this.state = state;
    this.setState = setState;
  }

  close() {
    this.setState(false);
  }

  open() {
    this.setState(true);
  }
}

export default DoorService;
