import React from "react";
import PropTypes from "prop-types";
import safeHtml from "web/core/utils/safeHtml";

const Heading = (defaultComponent, displayName, additionnalClasses) => {
  const HeadingComponent = ({ children, itemProp, as: asComponent }) => {
    const Component = asComponent || defaultComponent;

    return typeof children === "string" ? (
      <Component
        className={additionnalClasses ?? defaultComponent}
        dangerouslySetInnerHTML={{ __html: safeHtml(children) }}
        itemProp={itemProp}
      />
    ) : (
      <Component className={additionnalClasses ?? defaultComponent} itemProp={itemProp}>
        {children}
      </Component>
    );
  };

  HeadingComponent.displayName = displayName;

  HeadingComponent.propTypes = {
    children: PropTypes.node.isRequired,
    itemProp: PropTypes.string,
    as: PropTypes.string,
  };

  return HeadingComponent;
};

export default Heading;
