import { compose, withProps } from "recompose";
import useMediaQueryProps from "./useMediaQueryProps";

const withMediaQueryProps = compose(
  withProps(() => {
    return useMediaQueryProps();
  }),
);

export default withMediaQueryProps;
