import React from "react";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";
import {
  OptionalLink,
  FakeLink,
} from "theme/components/atoms/Typography/Link/";

import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import PrismicHeading from "../PrismicHeading";

const Block503 = ({ isDesktop, data }) => {
  return (
    <div className="block-prismic block-503">
      <div className="row">
        <div className="xxs12 md7">
          {data.image1 ? (
            <OptionalLink to={data.link}>
              <Image
                src={isDesktop ? data.image1.url : data.image_mobile.url}
                alt={data.image1.alt ?? data.title.text}
                format="original"
              />
            </OptionalLink>
          ) : null}
          <div className="flex nowrap start-xxs">
            {data.prefixe ? (
              <p className="h5 block-503__subtitle mr-3">{data.prefixe}</p>
            ) : null}
            <div className="block-503__content mt-2">
              {data.title ? (
                <OptionalLink to={data.link}>
                  <PrismicHeading
                    {...data.title}
                    additionnalClasses="block-503__title underline-animated-big"
                  />
                </OptionalLink>
              ) : null}
              <p className="mt-md-6 text-medium block-503__desc">
                <FakeLink to={data.link}>
                  {data.description ?? null} <i className="icon icon-arrow"></i>
                </FakeLink>
              </p>
            </div>
          </div>
        </div>
        {isDesktop ? (
          <div className="xxs12 md5">
            {data.image2 ? (
              <OptionalLink to={data.link}>
                <Image
                  src={data.image2.url}
                  alt={data.image2.alt ?? data.title.text}
                  format="original"
                />
              </OptionalLink>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block503);
