import React, { useMemo } from "react";
import Icon from "theme/components/atoms/Icon";
import { useIntl } from "react-intl";
import { validatePassword } from "theme/components/atoms/Form/Input/Password/passwordFieldValidator";
import { useFormData } from "theme/components/atoms/Form/Form/Form";
import ProgressStatus from "theme/components/atoms/ProgressStatus/ProgressStatus";
import passwordConfig from "../Password/passwordConfig";
import classnames from "classnames";
import PropTypes from "prop-types";

export const hasPasswordStrengthHint = () =>
  process.env.FRONT_COMMERCE_WEB_PASSWORD_HINT_DISABLE !== "true";

const PasswordStrengthHint = ({ formValuePath, passwordConfig: config }) => {
  if (!config) {
    config = passwordConfig;
  }
  const intl = useIntl();
  const password = useFormData(formValuePath);
  const validation = useMemo(
    () => validatePassword(password, config),
    [password, config]
  );

  if (!hasPasswordStrengthHint()) {
    return null;
  }

  return (
    <div className="password-strength-hint">
      <div>
        <ProgressStatus
          status={validation.status.status}
          progress={validation.status.progress}
          progressMessage={intl.formatMessage(validation.status.label)}
        />
        <ul className="password-strength-hint__criteria-wrapper">
          {validation.rules.map(rule => {
            return (
              <li
                key={rule.id}
                className={classnames("password-strength-hint__criteria", {
                  "password-strength-hint__criteria--valid": rule.valid,
                  "password-strength-hint__criteria--invalid": !rule.valid,
                })}
              >
                <span className="password-strength-hint__criteria__inner">
                  {rule.valid && (
                    <Icon icon="checkmark" title="ok" size="big" />
                  )}
                  {intl.formatMessage(rule.label)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

PasswordStrengthHint.propTypes = {
  formValuePath: PropTypes.string,
  passwordConfig: PropTypes.any,
};

export default PasswordStrengthHint;
