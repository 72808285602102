import React from "react";
import Accordion from "theme/components/molecules/Accordion";
import { defineMessages, useIntl } from "react-intl";
import Wysiwyg from "theme/modules/WysiwygV2";

const messages = defineMessages({
  materialTitle: {
    id: "modules.Prismic.Material.materialTitle",
    defaultMessage: "Material",
  },
  maintenanceTitle: {
    id: "modules.Prismic.Material.maintenanceTitle",
    defaultMessage: "Maintenance",
  },
});

const Material = ({ img, desc, maintenance }) => {
  const intl = useIntl();

  return (
    <div className="material py-5 py-md-6">
      <div className="material__img pb-3">
        <img src={img} style={{ width: "100%" }} alt="" />
      </div>
      <div className="material__accordion pt-3">
        <Accordion title={intl.formatMessage(messages.materialTitle)}>
          <Wysiwyg content={desc} />
        </Accordion>
      </div>
      <div className="material__accordion pt-3">
        <Accordion title={intl.formatMessage(messages.maintenanceTitle)}>
          <Wysiwyg content={maintenance} />
        </Accordion>
      </div>
    </div>
  );
};

export default Material;
