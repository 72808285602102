import React from "react";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import Form from "theme/components/atoms/Form/Form";
import EnhanceLoginForm from "./EnhanceLoginForm";
import Fieldset from "theme/components/atoms/Form/Fieldset";
import { Email, Password } from "theme/components/atoms/Form/Input";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import LoginMutation from "./LoginMutation.gql";
import { ErrorAlert } from "theme/components/molecules/Alert";
import FormItem from "theme/components/molecules/Form/Item";
import Link from "theme/components/atoms/Typography/Link";

const messages = defineMessages({
  submit: {
    id: "modules.User.LoginForm.submit",
    defaultMessage: "Sign in",
  },
  emailLabel: {
    id: "modules.User.LoginForm.email.label",
    defaultMessage: "Email",
  },
  emailErrorRequired: {
    id: "modules.User.LoginForm.email.errorRequired",
    defaultMessage: "Please enter an email",
  },
  emailErrorMalformed: {
    id: "modules.User.LoginForm.email.errorMalformed",
    defaultMessage: "Please enter a valid email address",
  },
  passwordLabel: {
    id: "modules.User.LoginForm.password.label",
    defaultMessage: "Password",
  },
  passwordErrorRequired: {
    id: "modules.User.LoginForm.password.errorRequired",
    defaultMessage: "Please enter a password",
  },
  forgotPassword: {
    id: "modules.User.LoginForm.forgotPassword",
    defaultMessage: "Forgot your password?",
  },
  linkNewAccount: {
    id: "modules.User.LoginForm.linkNewAccount",
    defaultMessage: "Première visite ? Créer un compte",
  },
});

const LoginForm = props => {
  const { formatMessage } = props.intl;
  return (
    <Form
      onValidSubmit={data => props.authenticateUser(data)}
      onChange={props.onChange}
    >
      <Fieldset>
        {props.errorMessage && (
          <ErrorAlert key="error">{props.errorMessage}</ErrorAlert>
        )}
        <FormItem label={formatMessage(messages.emailLabel)}>
          <Email
            id="email"
            name="email"
            value={props.value?.email}
            required
            aria-label={formatMessage(messages.emailLabel)}
            validationErrors={{
              required: formatMessage(messages.emailErrorRequired),
              isEmail: formatMessage(messages.emailErrorMalformed),
            }}
          />
        </FormItem>
        <FormItem label={formatMessage(messages.passwordLabel)}>
          <Password
            id="password"
            name="password"
            required
            aria-label={formatMessage(messages.passwordLabel)}
            validationError={formatMessage(messages.passwordErrorRequired)}
          />
        </FormItem>
        <div className="flex end-xxs mt-n1 mb-4 p6">
          <Link to="/account/password-request" appearance="underline">
            {formatMessage(messages.forgotPassword)}
          </Link>
        </div>
        <div className="btn-ctn btn-fw mb-3">
          <SubmitButton
            state={props.commandPending ? "pending" : undefined}
            appearance="primary"
          >
            {formatMessage(messages.submit)}
          </SubmitButton>
        </div>
        {props.openConnection ? (
          <div
            className="text-center color-light hidden-xxs show-md cursor"
            onClick={() => props.openConnection(false)}
          >
            {formatMessage(messages.linkNewAccount)}
          </div>
        ) : null}
        {props.additionalActions}
      </Fieldset>
    </Form>
  );
};

export default compose(
  injectIntl,
  EnhanceLoginForm({ LoginMutation })
)(LoginForm);
