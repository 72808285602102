import React from "react";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import ProductItem from "theme/modules/ProductView/ProductItem";
import ReactPlayer from "react-player";

const Block512 = ({ data }) => {
  const vimeo = data?.vimeo_link ?? null;
  return data.product && data.image ? (
    <div className="block-prismic block-512">
      <div className="block-512__image">
        {vimeo ? (
          <div className="video-ctn">
            <ReactPlayer
              url={vimeo}
              playing={true}
              playsinline={true}
              muted={true}
              loop={true}
              height="auto"
              width="auto"
            />
          </div>
        ) : (
          <Image
            src={data.image.url}
            alt={data.image.alt ?? data.product.fullName}
            format="original"
          />
        )}
      </div>
      <div className="block-512__product">
        <ProductItem
          key={data.product.sku}
          product={data.product}
          {...data.product}
        />
      </div>
    </div>
  ) : null;
};

export default compose(withMediaQueryProps)(Block512);
