import React from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";
import { OptionalLink } from "theme/components/atoms/Typography/Link/";
import PrismicHeading from "../PrismicHeading";
import Wysiwyg from "theme/modules/WysiwygV2";

const Block604 = ({ isDesktop, data }) => {
  const vimeo = data?.vimeo_link ?? null;
  const vimeo_mobile = data?.vimeo_link_mobile ?? null;
  return (
    <div className="block-prismic block-604">
      <div className="row">
        <div className="xxs12 md5 last-md">
          {vimeo ? (
            <div className="video-ctn">
              <ReactPlayer
                url={!isDesktop && vimeo_mobile ? vimeo_mobile : vimeo}
                playing={true}
                playsinline={true}
                muted={true}
                loop={true}
                height="auto"
                width="auto"
              />
            </div>
          ) : data.image ? (
            <OptionalLink to={data.image_link}>
              <Image
                src={isDesktop ? data.image.url : data.image_mobile.url}
                alt={data.image.alt ?? data.title.text}
              />
            </OptionalLink>
          ) : null}
        </div>

        <div className="xxs12 md7">
          <div className="block-604__content row fh">
            <PrismicHeading
              {...data.title}
              additionnalClasses="block-604__title hero-l xxs12 xl8"
            ></PrismicHeading>

            <div className="block-604__desc xxs12">
              <div className="flex bottom-xxs fh">
                <i className="icon icon-rivets"></i>
                <div className="xxs12 md5">
                  <Wysiwyg content={data.descriptionWysiwyg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block604);
