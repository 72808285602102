import React from "react";
import { useDoorService } from "theme/components/helpers/doorService";
import Panel from "theme/components/molecules/Panel";
import Material from "theme/modules/Prismic/Blocks/Product/Material";
import Image from "theme/components/atoms/Image";

const MaterialPanel = ({ block }) => {
  const doorService = useDoorService();
  return (
    <>
      {block.material && (
        <Panel
          open={doorService.state}
          onClose={() => doorService.close()}
          title={block.material.name ? block.material.name : block.matter_name}
        >
          <Material {...block.material} />
        </Panel>
      )}

      <div
        onClick={() => (block.material ? doorService.open() : null)}
        className={"block-620__item" + (block.material ? " cursor" : "")}
      >
        <Image
          src={block.image.url}
          alt={block.image.alt ?? block.matter_name}
          format="original"
        />
        {block.matter_name ? (
          <span className="h5">{block.matter_name}</span>
        ) : null}
      </div>
    </>
  );
};

export default MaterialPanel;
