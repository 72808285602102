// antadis : reverse label et input (fix autocompletion chrome)
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Label from "theme/components/atoms/Form/Label";

const Item = ({ label, children, appearance, required, name }) => {
  required =
    typeof required === "undefined" && React.isValidElement(children)
      ? children.props.required
      : required;

  name =
    typeof name === "undefined" && React.isValidElement(children)
      ? children.props.id || children.props.name
      : name;

  const classes = classNames({
    [`form-item`]: true,
    [`form-item--${appearance}`]: appearance,
    required,
  });

  if (process.env.NODE_ENV === "development" && !label && label !== null) {
    console.warn(
      "A FormItem should have a label. If you are sure about not setting it, please set value `null` for property `label`."
    );
  }

  return (
    <div className={classes}>
      <div className="form-item__input">{children}</div>
      {label && (
        <div className="form-item__label">
          <Label required={required} htmlFor={name}>
            {label}
          </Label>
        </div>
      )}
    </div>
  );
};

Item.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node.isRequired,
  appearance: PropTypes.oneOf(["default", "inline"]),
  required: PropTypes.bool,
  name: PropTypes.string,
};

export default Item;
