import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Money from "theme/components/atoms/Typography/Money";
import { currenciesAllowedList } from "theme/components/atoms/Typography/Money/Money";
import invariant from "fbjs/lib/invariant";

/**
 * @deprecated use `currenciesAllowedList` instead
 */
export const currenciesWhitelist = currenciesAllowedList;
export { currenciesAllowedList };

/**
 * @typedef {PropTypes.InferProps<typeof propTypes>} ProductPriceProps
 *
 * @type {React.FunctionComponent<ProductPriceProps>}
 */
const Price = (props) => {
  if (props.price) {
    const {
      price: { value },
    } = props;
    invariant(
      currenciesAllowedList.indexOf(value?.currency) > -1,
      `"${value?.currency}" is not on the allowed currency list`
    );

    return (
      <span className={`price`}>
        {value?.amount ? (
          <Money value={value} />
        ) : (
          <FormattedMessage
            id="components.atoms.Typography.Price.notApplicable"
            defaultMessage="N/A"
          />
        )}
      </span>
    );
  }
  return null;
};

/* eslint-disable react/forbid-foreign-prop-types */
const propTypes = {
  price: PropTypes.shape({
    value: Money.propTypes.value,
  }),
};
Price.propTypes = propTypes;
/* eslint-enable react/forbid-foreign-prop-types */

export default Price;
