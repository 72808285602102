// voir si on surcharge pour avoir un déroulé js - sinon suppr le fichier
import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import classNames from "classnames";
import withProps from "recompose/withProps";
import { FormattedMessage } from "react-intl";
import Icon from "theme/components/atoms/Icon";
import withFormHandlers from "../withFormHandlers";
import BaseInput from "../BaseInput";
import Label from "theme/components/atoms/Form/Label";

// Attention : Note that if you've passed something else than `undefined`
// (e.g. `value`) to the `value` prop the input will be considered valid.
// This is especially important for placeholder options such as "Select a country".
const Select = (props) => {
  const {
    getErrors,
    isValid,
    isPristine,
    isDirty,
    value,
    placeholder,
    options,
    onChange,
    onBlur,
    appearance,
    ...rest
  } = props;
  const errors = getErrors();

  const classes = classNames("select-input", {
    [`select-input--${appearance}`]: appearance,
    "select-input--invalid": getErrors().length,
    "select-input--valid": isValid(),
  });

  return (
    <BaseInput
      input={
        <div className={classes}>
          <select
            className={(!value || value === placeholder) && 'disabled'}
            {...rest}
            value={value || placeholder}
            onChange={(event) => {
              onChange(event);
            }}
          >
            {options.map((option) => {
              return (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              );
            })}
          </select>
          <Icon icon="chevron-right" title="" size="small" />
          {placeholder && (
            <div className="form-item__label">
              <Label>
                {placeholder}
              </Label>
            </div>
          )}
        </div>
      }
      help={props.help}
      errors={errors}
      {...props.baseInputProps}
    />
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placheolder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default compose(
  withProps((props) => ({
    validationError: (
      <FormattedMessage
        id="components.atoms.Form.Input.Select.requiredError"
        defaultMessage="You must select an option"
      />
    ),
    value: props.value,
    options: props.placeholder
      ? [
          {
            value: null,
            label: props.placeholder,
            disabled: props.required,
          },
          ...props.options,
        ]
      : props.options,
  })),
  withFormHandlers({
    dirtyOnChange: true,
    getValueFromProps: (props) => props.value,
    defaultGetPropsFromValue: (value) => ({
      value: value,
    }),
  })
)(Select);
