import React, { useState } from "react";
import Input from "../Input";
import IconButton from "theme/components/atoms/Button/IconButton";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";

const messages = defineMessages({
  showPassword: {
    id: "components.atoms.Form.Input.Password.showPasswordTitle",
    defaultMessage: "Show password",
  },
  hidePassword: {
    id: "components.atoms.Form.Input.Password.hidePasswordTitle",
    defaultMessage: "Hide password",
  },
});

const Password = ({ disableShowPassword, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="password">
      <Input {...props} type={showPassword ? "text" : "password"} />
      {!disableShowPassword && (
        <div className={"password__show-icon input-height"}>
          <IconButton
            icon={showPassword ? "eye-off" : "eye"}
            onClick={toggleShowPassword}
            type="button"
            iconSize="default"
            state={undefined}
            title={intl.formatMessage(
              showPassword ? messages.hidePassword : messages.showPassword
            )}
          />
        </div>
      )}
    </div>
  );
};

Password.propTypes = {
  disableShowPassword: PropTypes.bool,
};

export default Password;
