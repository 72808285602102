// ajout des class de validité sur le parent de l'input
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const BaseInput = (props) => {
  const className = classnames("input-wrapper", {
    [`input-wrapper--${props.appearance}`]: props.appearance,
  });
  return (
    <div className={`${className} ${props.className ?? ""}`}>
      {props.input}
      {props.help && <div className="input-wrapper__help">{props.help}</div>}
      {props.errors &&
        props.errors.map((error) => (
          <div
            key={error}
            className={classnames("input-wrapper__error", {
              [`input-wrapper__error--${props.errorAppearance}`]:
                props.errorAppearance,
            })}
          >
            {error}
          </div>
        ))}
    </div>
  );
};

BaseInput.propTypes = {
  appearance: PropTypes.oneOf(["default", "invisible", "vertical", "start", "hidden"]),
  errorAppearance: PropTypes.oneOf(["default", "start"]),
};

export default BaseInput;
