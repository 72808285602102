import Breakpoints from "theme/components/atoms/Breakpoints";
import { useMediaQuery } from "react-responsive";

const matches = ({ minWidth, maxWidth }, width) =>
    (!minWidth || minWidth <= width) && (!maxWidth || width <= maxWidth);

const useIsServer = () => typeof window === 'undefined' || typeof window.document === 'undefined';

const useMediaQueryClientIsDesktop = () => useMediaQuery({
    query: `(min-width: ${Breakpoints.desktop}px)`,
})

const useMediaQueryClientIsTablet = () => useMediaQuery({
    query: `(min-width: ${Breakpoints.tablet}px) and (max-width: ${
        Breakpoints.desktop - 1
    }px)`,
});

const useMediaQueryClientIsMobile = () => useMediaQuery({
    query: `(max-width: ${Breakpoints.tablet - 1}px)`,
});

const useMediaQueryServerIsDesktop = (device) => matches(
    { minWidth: Breakpoints.desktop },
    device.viewportWidthInPx
);

const useMediaQueryServerIsTablet = (device) => matches(
    {minWidth: Breakpoints.tablet, maxWidth: Breakpoints.desktop - 1},
    device.viewportWidthInPx
);

const useMediaQueryServerIsMobile = (device) => matches(
    { maxWidth: Breakpoints.tablet - 1 },
    device.viewportWidthInPx
);

export {
    useMediaQueryClientIsDesktop,
    useMediaQueryClientIsMobile,
    useMediaQueryClientIsTablet,
    useMediaQueryServerIsDesktop,
    useMediaQueryServerIsMobile,
    useMediaQueryServerIsTablet,
    useIsServer
}
