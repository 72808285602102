import { useDevice } from "theme/components/helpers/MediaQuery";
import {
  useIsServer,
  useMediaQueryServerIsTablet,
  useMediaQueryServerIsMobile,
  useMediaQueryServerIsDesktop,
  useMediaQueryClientIsTablet,
  useMediaQueryClientIsMobile,
  useMediaQueryClientIsDesktop,
} from "./baseHooks";

const useMediaQueryPropsForServerSide = () => {
  const device = useDevice();

  return {
    isMobile: useMediaQueryServerIsMobile(device),
    isTablet: useMediaQueryServerIsTablet(device),
    isDesktop: useMediaQueryServerIsDesktop(device),
    isServer: true,
  };
};

const useMediaQueryPropsForClientSide = () => {
  return {
    isMobile: useMediaQueryClientIsMobile(),
    isTablet: useMediaQueryClientIsTablet(),
    isDesktop: useMediaQueryClientIsDesktop(),
    isServer: false,
  };
};

const useMediaQueryProps = () => {
  const isServer = useIsServer();
  const ssrMediaQueryProps = useMediaQueryPropsForServerSide();
  const clientMediaQueryProps = useMediaQueryPropsForClientSide();

  return isServer ? ssrMediaQueryProps : clientMediaQueryProps;
};

export default useMediaQueryProps;
