import React from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";
import Wysiwyg from "theme/modules/WysiwygV2";
import PrismicHeading from "../PrismicHeading";

const Block611 = ({ isMobile, data }) => {
  const vimeo = data?.vimeo_link ?? null;
  const vimeo_mobile = data?.vimeo_link_mobile ?? null;

  return (
    <div className="block-prismic block-611 border-top pt-5 pt-md-8">
      <div className="row bottom-sm">
        <div className="xxs12 sm6">
          {data.prefixe || data.title.text ? (
            <div className="block-611__title h3">
              {data.prefixe ? <span>{data.prefixe}</span> : <span></span>}
              <span className="d-ib">
                {data.title.text ? <PrismicHeading {...data.title} /> : null}
              </span>
            </div>
          ) : null}
          {vimeo ? (
            <div className="video-ctn">
              <ReactPlayer
                url={isMobile && vimeo_mobile ? vimeo_mobile : vimeo}
                playing={true}
                playsinline={true}
                muted={true}
                loop={true}
                height="auto"
                width="auto"
              />
            </div>
          ) : data.image ? (
            <Image
              src={isMobile ? data.image.url : data.image_mobile.url}
              alt={data.image.alt ?? data.prefixe}
            />
          ) : null}
        </div>
        <div
          className={`xxs12 sm6 md3 mt-8 mt-md-5${
            data.reverse ? " first-sm" : ""
          }`}
        >
          {data.descriptionWysiwyg ? (
            <Wysiwyg content={data.descriptionWysiwyg} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block611);
