import React from "react";
import { withRouter } from "react-router";
import Form from "theme/components/atoms/Form/Form";
import Select from "theme/components/atoms/Form/Input/Select";

const LinkListSelector = (props) => {
  const selectedPath = [...props.paths]
    .reverse()
    .find(({ value }) => props.location.pathname.startsWith(value));
  return (
    <Form
      onChange={(data) => {
        data.navigation !== "/user/logout"
          ? props.history.push(data.navigation)
          : props.selectMenu("logout");
      }}
    >
      <Select
        value={selectedPath?.value}
        name="navigation"
        id="navigation"
        options={props.paths}
        appearance={props.appearance}
      />
    </Form>
  );
};

export default withRouter(LinkListSelector);
