module.exports = {
  defaultBgColor: "FFFFFF",
  presets: {
    swatch: { width: 26, height: 26, bgColors: [] },
    thumbnail: { width: 50, height: 50, bgColors: [] },
    small: { width: 118, height: 140, bgColors: [] },
    galleryPreview: { width: 136, height: 136, bgColors: [] },
    medium: { width: 296, height: 384, bgColors: [] },
    product: { width: 400, height: 510, bgColors: [] },
    productList: { width: 378, height: 404, bgColors: [] },
    productListHover: { width: 1320, height: 1720, bgColors: [] },
    large: { width: 1100, height: 1100, bgColors: [] },
    newsletter: { width: 1280, height: 242, bgColors: [] },
    carousel: { width: 1280, height: 600, bgColors: [] },
    pushBlock: { width: 284, height: 354, bgColors: [] },
    pushBlockWide: { width: 568, height: 354, bgColors: [] },
    cartPreview: { width: 70, height: 90, bgColors: [] },
    wishlistPreview: { width: 50, height: 50, bgColors: [] },
  },
};
