const isTouch =
  !!(
    typeof window !== "undefined" &&
    ("ontouchstart" in window ||
      (window.DocumentTouch &&
        typeof document !== "undefined" &&
        document instanceof window.DocumentTouch))
  ) ||
  !!(
    typeof navigator !== "undefined" &&
    (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
  );

export default isTouch;
