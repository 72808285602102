module.exports = {
  default_image_url: "https://placehold.it/150x220",
  available_page_sizes: [1000],
  mapsKey: "AIzaSyB451F91Eed54R7ToFu8_tzH-0IMyN66YI",
  color_attribute_id: "93",
  website_id: 1,
  tax: 1.2,
  cookieMaxAgeInMonths: 12,
  contentSecurityPolicy: {
    // Disabling CSP while awaiting for a definitive choice and
    // suggestion from Adyen about CSPs.
    // It prevents payment errors during 3DS challenges loading
    // 3rd party bank iframes in the site from different domains.
    //
    // see https://github.com/Adyen/adyen-magento2/issues/1010#issuecomment-828217878
    __dangerouslyDisable: true,

    directives: {
      scriptSrc: [
        "http://www.googletagmanager.com",
        "https://cdn.shipup.co",
        "https://connect.facebook.net",
        "https://www.facebook.com",
        "www.paypalobjects.com",
        "*.paypal.com",
        "static.cdn.prismic.io",
        "prismic.io",
        "https://html2canvas.hertzen.com/dist/html2canvas.min.js",
        "*www.google-analytics.com",
      ],
      frameSrc: [
        "https://player.vimeo.com",
        "https://connect.facebook.net",
        "https://www.facebook.com",
        "www.paypalobjects.com",
        "*.paypal.com",
        "*.adyen.com",
      ],
      styleSrc: ["https://i.icomoon.io", "https://cdn.shipup.co"],
      fontSrc: ["https://i.icomoon.io"],
      imgSrc: [
        "https://images.prismic.io",
        "https://i.vimeocdn.com",
        "https://preprod-fc.jerome-dreyfuss.com",
        "https://shipup-assets-prod.s3.amazonaws.com",
        "https://connect.facebook.net",
        "https://www.facebook.com",
        "*www.google-analytics.com",
        "www.paypalobjects.com",
        "*.paypal.com",
        "*.adyen.com",
      ],
      connectSrc: [
        "*www.google-analytics.com",
        "https://api.shipup.co",
        "www.paypalobjects.com",
        "*.paypal.com",
        "*.adyen.com",
      ],
      baseUri: [],
      defaultSrc: [
        "https://player.vimeo.com",
        "https://vod-progressive.akamaized.net",
      ],
    },
  },
  homepage_identifier: "home",
  defaultTitle: "Jérôme Dreyfuss",
  defaultDescription: "Boutique officielle de Jérôme Dreyfuss",
  themeColor: "#F9F7F1",
  policyUrl: "/privacy-policy",
  cgvUrl: "/cgv",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [],
    attributeFacetMinimumDocumentCount: 1,
    authorizedCategoriesFacet: [],
    categoryFacetMinimumDocumentCount: 1,
  },
  phoneNumber: "05 61 40 54 92",
  email: "contact@jerome-dreyfuss.com",
  rewrittenToRoot: ["/home"],
  useUserTitles: false,
};
