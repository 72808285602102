import React from "react";
import getWysiwygComponent from "./getWysiwygComponent";

const WysiwygV2 = React.memo(({ content, ...rest }) => {
  const isValidContent = Boolean(content?.__typename);
  if (process.env.NODE_ENV === "development" && !isValidContent) {
    console.warn(
      "The `<Wysiwyg>` component was called with an empty content or a string. Please ensure that the content is fully loaded from a GraphQL query using the `WysiwygFragment` for this content field before displaying the `<Wysiwyg>` component"
    );
  }

  const Component = getWysiwygComponent(content?.__typename);
  return <>{isValidContent && <Component content={content} {...rest} />}</>;
});

export default WysiwygV2;
