import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Carousel from "theme/components/organisms/Carousel/Carousel";
import Wysiwyg from "theme/modules/WysiwygV2";
import PrismicHeading from "../PrismicHeading";
import MaterialPanel from "./MaterialPanel";

const Block620 = ({ isDesktop, data }) => {
  const blocks = data.matter.filter((block) => block.matter_name);

  if (!blocks.length) {
    return null;
  }

  return (
    <div className="block-prismic block-620">
      <div className="flex start-xxs middle-xxs pl-1 pl-md-2">
        <div className="xxs12 md4">
          <div className="flex start-xxs">
            {data.title ? (
              <PrismicHeading
                {...data.title}
                additionnalClasses="h1 block-620__title"
              />
            ) : null}
            {data.suffix ? (
              <span className="block-620__number ml-1">{data.suffix}</span>
            ) : null}
          </div>
        </div>
        <div className="p2 xxs12 md7 mt-5 mt-md-0">
          {data.descriptionWysiwyg ? (
            <Wysiwyg content={data.descriptionWysiwyg} />
          ) : null}
        </div>
      </div>
      <div className="block-620__content pl-2 pl-md-4 mt-3 mt-md-4">
        <div className="border-top pt-4 pt-md-5 mr-md-4"></div>
        <div className="row pb-4 pb-md-5">
          <Carousel>
            <div className="flex nowrap block-620__list">
              {blocks.map((block, i) => {
                return <MaterialPanel block={block} key={`block-620-${i}`} />;
              })}
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block620);
