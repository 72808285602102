import React from "react";
import PropTypes from "prop-types";
import Image from "theme/components/atoms/Image";
import ProductOverview from "./Overview";
import ProductLabel from "./ProductLabel";
import ProductItemActions from "./ProductItemActions";
import { FakeLink } from "theme/components/atoms/Typography/Link/";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";

const ProductItem = (props) => {
  const { hoverOnly = false } = props;
  const { isDesktop } = useMediaQueryProps();

  return (
    <div
      itemProp={props.itemProp}
      itemScope
      itemType="http://schema.org/Product"
      className="product-item"
    >
      <div className="product-item__inner">
        <FakeLink to={props.product.path}>
          <div className="product-item__image">
            <Image
              itemProp="image"
              src={props.product.imageUrl}
              alt={props.product.name}
              format="productList"
              priority={props.imagePriority}
            />
          </div>
          {props.product.imageHover && isDesktop && (
            <div
              className={
                "product-item__hover" +
                (hoverOnly ? " product-item__hover--only" : "")
              }
            >
              <Image
                src={props.product.imageHover}
                alt={props.product.name}
                format="productListHover"
                priority={props.imagePriority}
              />
            </div>
          )}
        </FakeLink>

        <div className="product-item__label">
          <ProductLabel product={props.product} />
        </div>

        <div className="product-item__overview">
          <ProductOverview
            product={props.product}
            products={props.products}
            breadcrumb={props.breadcrumb}
            location={props.location}
            index={props.index}
          />
        </div>
        <div className="product-item__actions">
          <ProductItemActions product={props.product} />
        </div>
        <meta itemProp="category" content={props.categoryName} />
      </div>
    </div>
  );
};

ProductItem.propTypes = {
  categoryName: PropTypes.string,
};

export default ProductItem;
