import React from "react";
import Icon from "../Icon";
import Button from "./Button";

const IconButton = ({ icon, iconSize, title, label, ...props }) => {
  return (
    <Button
      {...props}
      appearance="icon"
      title={title}
    >
      {label !== undefined ? <span className="icon-label">{label}</span> : null}
      <Icon
        icon={icon}
        size={iconSize}
        title={title}
      />
    </Button>
  );
};

IconButton.propTypes = {
  ...Button.propTypes,
  title: Icon.propTypes.title,
  icon: Icon.propTypes.icon,
  iconSize: Icon.propTypes.size,
};
/* eslint-disable-next-line react/forbid-foreign-prop-types */
if (IconButton.propTypes) {
  /* eslint-disable-next-line react/forbid-foreign-prop-types */
  delete IconButton.propTypes.buttonType;
  /* eslint-disable-next-line react/forbid-foreign-prop-types */
  delete IconButton.propTypes.children;
}

export default IconButton;
