import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";

const Block613 = ({ isDesktop, data }) => {
  return data.quote ? (
    <div className="block-prismic block-613 border-top pt-5 pt-md-8">
      <div
        className={`row ${data.reverse ? "start-xxs" : "end-xxs"} between-sm`}
      >
        <span
          className={`xxs12 text-left ${
            !data.image ? "md12 h1" : "md8 hero-m"
          }${data.reverse ? " last-sm" : ""}`}
        >
          {data.quote}
        </span>
        <div className="mt-6 mt-md-0 xxs6 md3">
          {data.image ? (
            <Image
              src={isDesktop ? data.image.url : data.image_mobile.url}
              alt={data.image.alt ?? ""}
              format="original"
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default compose(withMediaQueryProps)(Block613);
