import React from "react";
import { FormattedMessage } from "react-intl";
import { H1 } from "theme/components/atoms/Typography/Heading";
import Link from "theme/components/atoms/Typography/Link";
import Image from "theme/components/atoms/Image";
import useFullUrl from "web/core/shop/useFullUrl";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Icon from "theme/components/atoms/Icon";

const NotFound = ({ isDesktop }) => {
  const imgPathFull = useFullUrl("/images/404.jpg");
  const imgPathFullMobile = useFullUrl("/images/404-mobile.jpg");
  const alt = "404";
  return (
    <div className="page-content--not-found">
      <div className="page-content--not-found__inner row bottom-md">
        <div className="xxs12 md5 img-ctn">
          <Image src={isDesktop ? imgPathFull : imgPathFullMobile} alt={alt} />
        </div>
        <div className="xxs12 md6">
          <div className={isDesktop ? "title-rivet-vertical" : ""}>
            <div
              className={
                !isDesktop ? "title-rivet-vertical xs-ctn left " : "xs-ctn left"
              }
            >
              <H1>
                <FormattedMessage
                  id="modules.PageError.NotFound.title"
                  defaultMessage="404 : page not found"
                />
              </H1>
            </div>
            <div className="subtitle xxs-ctn right mt-md-9 flex end-md">
              <div className="text-left">
                <div>
                  <FormattedMessage
                    id="modules.PageError.NotFound.message1"
                    defaultMessage="Unfortunately, the searched page does not correspond to any active page of our site."
                  />
                </div>
                <div className="mt-3 mt-md-5 d-ib">
                  <Link to="/">
                    <div className="flex">
                      <span className="underline">
                        <FormattedMessage
                          id="modules.PageError.NotFound.linkHomepage"
                          defaultMessage="Front-Commerce homepage"
                        />
                      </span>
                      <div className="ml-3">
                        <Icon
                          icon="arrow-right"
                          title="arrow-right"
                          size="big"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(NotFound);
