import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import isTouch from "theme/components/helpers/isTouch";

const Carousel = ({ children }) => {
  return (
    <>
      {!isTouch ? (
        <ScrollContainer className="scroll-container" vertical={false}>
          {children}
        </ScrollContainer>
      ) : (
        <div className="carousel-wrapper flex nowrap start-xxs fw">
          {children}
        </div>
      )}
    </>
  );
};

export default Carousel;
