import React from "react";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";

import {
  OptionalLink,
  FakeLink,
} from "theme/components/atoms/Typography/Link/";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import PrismicHeading from "../PrismicHeading";

const Block502 = ({ isDesktop, data }) => {
  return data !== undefined &&
    (data.image1 || data.title1 || data.image2 || data.title2) ? (
    <div className="block-prismic block-502">
      <div className="row">
        {data.image1 || data.title1 ? (
          <div className="block-502__item xxs12 md6">
            {data.image1 ? (
              <FakeLink to={data.link1}>
                <Image
                  src={isDesktop ? data.image1.url : data.image1_mobile.url}
                  alt={data.image1.alt ?? data.title1.text}
                  format="original"
                />
              </FakeLink>
            ) : null}
            {data.title1 ? (
              <div className="flex nowrap start-xxs">
                <span className="block-501__prefixe">{data.prefixe1}</span>
                <OptionalLink to={data.link1}>
                  <PrismicHeading
                    {...data.title1}
                    additionnalClasses="block-501__title"
                  />
                </OptionalLink>
              </div>
            ) : null}
          </div>
        ) : null}
        {data.image2 || data.title2 ? (
          <div className="block-502__item xxs12 md6 mt-5 mt-md-0">
            {data.image2 ? (
              <FakeLink to={data.link2}>
                <Image
                  src={isDesktop ? data.image2.url : data.image2_mobile.url}
                  alt={data.image2.alt ?? data.title2.text}
                  format="original"
                />
              </FakeLink>
            ) : null}
            {data.title2 ? (
              <div className="flex nowrap start-xxs">
                <span className="block-501__prefixe">{data.prefixe2}</span>
                <OptionalLink to={data.link2}>
                  <PrismicHeading
                    {...data.title2}
                    additionnalClasses="block-501__title"
                  />
                </OptionalLink>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default compose(withMediaQueryProps)(Block502);
