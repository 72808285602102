import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import ProductItem from "theme/modules/ProductView/ProductItem";
import {
  OptionalLink,
  FakeLink,
} from "theme/components/atoms/Typography/Link/";
import Carousel from "theme/components/organisms/Carousel/Carousel";
import Wysiwyg from "theme/modules/WysiwygV2";

const Block513 = ({ data }) => {
  const products = data.products.filter((product) => product);

  if (!products.length) {
    return null;
  }

  return (
    <div className="block-prismic block-513">
      <div className="flex pl-1 pl-md-2 ">
        <header className="xxs12 md4">
          <div className="block-513__header flex-md col-md fh">
            <div>
              {data.prefixe ? (
                <span className="h5 d-b">{data.prefixe}</span>
              ) : null}
              {data.titleWysiwyg ? (
                <OptionalLink to={data.link}>
                  <span className="hero-l">
                    <Wysiwyg content={data.titleWysiwyg} />
                  </span>
                </OptionalLink>
              ) : null}
            </div>
            <div className="block-513__desc">
              {data.descriptionWysiwyg ? (
                <div className="p1 text-medium mt-5 mr-7">
                  <FakeLink to={data.link}>
                    <Wysiwyg content={data.descriptionWysiwyg} />
                    <i className="mt-6 mt-md-3 icon icon-arrow"></i>
                  </FakeLink>
                </div>
              ) : null}
            </div>
          </div>
        </header>

        <div className="block-513__carousel xxs12 md8 mt-5 pl-0 pl-md-2">
          <div className="row start-xxs nowrap">
            <Carousel>
              {products.map((product, i) => {
                return (
                  <ProductItem
                    key={`513-${product.sku}${i}`}
                    product={product}
                    {...product}
                  />
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block513);
