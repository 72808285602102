import React from "react";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";
import { ProductPrice } from "theme/components/atoms/Typography/Price";
import { useLocation } from "react-router";
import RatingAverage from "theme/modules/Reviews/RatingAverage";
import useFullUrl from "web/core/shop/useFullUrl";
import { useIsNoEshop } from "theme/components/helpers/shop";

const messages = defineMessages({
  soldOut: {
    id: "modules.ProductView.ProductItem.soldOut",
    defaultMessage: "Sold out",
  },
});

const isConfigurableSoldOut = (products) => {
  return !products.some(({ product }) => product?.stock?.qty > 0);
}

const ProductOverview = ({ product, products, index, breadcrumb }) => {
  const location = useLocation();
  const intl = useIntl();
  const seoUrl = useFullUrl(product.path);

  const hasSticker = product.sticker && product.sticker !== "false";
  const isSoldOut = isConfigurableSoldOut(product.configurations) === true;
  const isSimpleSoldOut = product?.stock && product?.stock?.qty <= 0;

  return (
    <>
      {product.configurations?.length > 0 ? (
        <span className="product-overview__sticker">
          {hasSticker && isSoldOut ? `${intl.formatMessage(messages.soldOut)} / ${product.sticker}` : 
          (hasSticker ? product.sticker : (isSoldOut ? intl.formatMessage(messages.soldOut) : null))}
        </span>
      ) : (
        <span className="product-overview__sticker">
          {hasSticker && isSimpleSoldOut ? `${intl.formatMessage(messages.soldOut)} / ${product.sticker}` : 
          (hasSticker ? product.sticker : (isSimpleSoldOut ? intl.formatMessage(messages.soldOut) : null))}
        </span>
      )}
      <div className="product-overview flex-md">
        <meta itemProp="sku" content={product.sku} />
        <TrackingLink
          eventName="Product Clicked"
          eventPayload={{ sku: product.sku, name: product.name }}
          to={{
            pathname: product.path,
            state: location
              ? {
                  returnTo: `${location.pathname}${location.search}`,
                  products:
                    products &&
                    products.map(({ sku, path }) => ({ sku, path })),
                  selected: index,
                  breadcrumb: breadcrumb,
                }
              : { breadcrumb: breadcrumb },
          }}
          clickType="block"
        >
          <div
            className="product-overview__fullname row start-xxs top-xxs middle-sm my-0 h6"
            itemProp="name"
          >
            <div className="product-overview__name xxs4 md pr-1 pr-md-3">
              {product.name}
            </div>
            <div className="product-overview__subtitle xxs8 md">
              {product.subtitle}
            </div>
          </div>
        </TrackingLink>
        {product?.reviewsLayer?.total > 0 ? (
          <div className="product-overview__rating">
            <RatingAverage
              itemProp="aggregateRating"
              reviewsLayer={product.reviewsLayer}
              size="small"
            />
          </div>
        ) : null}
        <div className="row">
          <div
            className="product-overview__price offset-xxs4 offset-md0 xxs8 md h6 my-0"
            itemProp="offers"
            itemScope
            itemType="http://schema.org/Offer"
          >
            <meta itemProp="url" content={seoUrl} />
            {!useIsNoEshop() && <ProductPrice prices={product.prices} />}
          </div>
        </div>
      </div>
    </>
  );
};

ProductOverview.propTypes = {
  product: PropTypes.object.isRequired,
  products: PropTypes.array,
  breadcrumb: PropTypes.array,
  index: PropTypes.number,
};

export default ProductOverview;
