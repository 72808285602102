import React from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import { OptionalLink } from "theme/components/atoms/Typography/Link/";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import ProductItem from "theme/modules/ProductView/ProductItem";
import Image from "theme/components/atoms/Image";

const Block515 = ({ isDesktop, data }) => {
  const products = data.products
    ? data.products.filter((product) => product)
    : null;
  const vimeo = data?.vimeo_link ?? null;
  const vimeo_mobile = data?.vimeo_link_mobile ?? null;
  const img_mobile = data?.image_mobile?.url ?? null;

  if (!products.length) {
    return null;
  }

  return (
    <div className="block-prismic block-515 row start-xxs">
      {vimeo ? (
        <div className="xxs6 sm4 md3 mt-6">
          <div className="video-ctn">
            <ReactPlayer
              url={!isDesktop && vimeo_mobile ? vimeo_mobile : vimeo}
              playing={true}
              playsinline={true}
              muted={true}
              loop={true}
              height="auto"
              width="auto"
            />
          </div>
        </div>
      ) : data.image ? (
        <div className="xxs6 sm4 md3 mt-6">
          <OptionalLink to={data.image_link}>
            <Image
              src={
                isDesktop
                  ? data.image.url
                  : img_mobile
                  ? img_mobile
                  : data.image.url
              }
              alt={data.image.alt ?? ""}
              format="original"
            />
          </OptionalLink>
        </div>
      ) : null}
      {products.map((product, i) => {
        return (
          <div className="xxs6 sm4 md3 mt-6" key={`515-${product.sku}${i}`}>
            <ProductItem product={product} {...product} />
          </div>
        );
      })}
    </div>
  );
};

export default compose(withMediaQueryProps)(Block515);
