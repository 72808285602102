import React from "react";
import { FormattedMessage } from "react-intl";
import { BodyStrong } from "theme/components/atoms/Typography/Body";

const RedirectToLogin = ({ renderLink }) => {
  return (
    <div className="center">
      <FormattedMessage
        id="pages.Register.alreadyAMember"
        defaultMessage="Already a member?"
      />{" "}
      <BodyStrong>
        {renderLink({
          children: (
            <FormattedMessage
              id="pages.Register.signIn"
              defaultMessage="Sign in"
            />
          ),
        })}
      </BodyStrong>
    </div>
  );
};

export default RedirectToLogin;
