import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";
import {
  OptionalLink,
  FakeLink,
} from "theme/components/atoms/Typography/Link/";
import PrismicHeading from "../PrismicHeading";
import Wysiwyg from "theme/modules/WysiwygV2";

const Block603Title = ({ title, link, reverse }) => {
  const additionnalClasses = `hero-${
    title.text?.length > 15 ? "m mt-2 mt-md-5" : "l"
  } block-603__title flex${link && !reverse ? " with-arrow" : ""}`;
  return (
    <PrismicHeading {...title} additionnalClasses={additionnalClasses}>
      <span className="block-603__title-text">{title.text}</span>
    </PrismicHeading>
  );
};

const Block603 = ({ isTablet, isDesktop, data }) => {
  return (
    <div className={`block-prismic block-603${data.reverse ? " reverse" : ""}`}>
      <FakeLink to={data.link}>
        <div className="block-603__img-wrapper">
          <div className="row">
            <div className="xxs12 sm4">
              <div className="col-flex fh">
                {data.image1 ? (
                  <Image
                    src={
                      isDesktop || isTablet
                        ? data.image1.url
                        : data.image1_mobile.url
                    }
                    alt={data.image1.alt ?? data.title.text}
                  />
                ) : null}
                {data.descriptionWysiwyg ? (
                  <div className="block-603__description">
                    <Wysiwyg content={data.descriptionWysiwyg} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="xxs12 sm8">
              {data.image2 && (isTablet || isDesktop) ? (
                <Image
                  src={data.image2.url}
                  alt={data.image2.alt ?? data.title.text}
                  format="original"
                />
              ) : null}
            </div>
          </div>
        </div>
      </FakeLink>
      <div className="block-603__title-wrapper">
        <div className="row">
          <div className="xxs12 sm4">
            {data.prefixe && !data.reverse ? (
              <OptionalLink to={data.link}>
                <span className="h5">{data.prefixe}</span>
              </OptionalLink>
            ) : null}
          </div>
          <div className="xxs12 sm8">
            <OptionalLink to={data.link}>
              <Block603Title {...data} />
            </OptionalLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block603);
