import React, { useState } from "react";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import ProductItem from "theme/modules/ProductView/ProductItem";
import Carousel from "theme/components/organisms/Carousel/Carousel";

const Block510 = ({ isDesktop, isTablet, data }) => {
  const [tabOpen, setTabOpen] = useState(0);

  if (!data.products || !data.image) {
    return null;
  }

  const { products } = data;
  const currentProduct = products[tabOpen];

  return (
    <div className="block-prismic block-510">
      <div className="block-510__ctn">
        <Image
          src={isTablet || isDesktop ? data.image.url : data.image_mobile.url}
          cover
          alt={data.image.alt ?? currentProduct.name}
        />

        <div className="block-510__product">
          <ProductItem
            key={currentProduct.sku}
            product={currentProduct}
            {...currentProduct}
          />
        </div>
      </div>
      <div className="flex">
        <div className="block-510__nav-ctn flex nowrap top-xxs start-xxs mt-1 mt-sm-4">
          {data.prefixe ? (
            <span className="block-510__brand mr-2 mb-3 mb-sm-0">
              {data.prefixe}
            </span>
          ) : null}
          <Carousel>
            <div className="block-510__nav flex start-xxs">
              {products.map((el, i) => (
                <div
                  key={`producttab${i}`}
                  className={`block-510__nav-link${
                    i === tabOpen ? " active" : ""
                  }`}
                  onClick={() => setTabOpen(i)}
                >
                  {el.name}
                </div>
              ))}
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block510);
