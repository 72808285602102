// see https://developers.front-commerce.com/docs/2.x/advanced/analytics/getting-started
// to properly configure analytics on your project.
module.exports = {
  analytics: {
    enable: true,
    debug: process.env.NODE_ENV !== "production",
    defaultSettings: {},
    plugins: [
      {
        name: "google-tag-manager",
        needConsent: false,
        settings: (authorization, otherAuthorizations) => ({
          containerId: process.env.FRONT_COMMERCE_WEB_ANALYTICS_GTM,
          // the userConsents option is a specific key that the plugin will use and expose in the GTM dataLayer
          userConsents: otherAuthorizations,
        }),
        script: () => import("@analytics/google-tag-manager"),
      },
    ],
  },
};
