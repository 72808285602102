import Link from "theme/components/atoms/Typography/Link/Link";
import PrimaryLink from "theme/components/atoms/Typography/Link/PrimaryLink";
import ButtonLink from "theme/components/atoms/Typography/Link/ButtonLink";
import SmallLink from "theme/components/atoms/Typography/Link/SmallLink";

import OptionalLink from "theme/components/atoms/Typography/Link/OptionalLink";
import FakeLink from "theme/components/atoms/Typography/Link/FakeLink";

export default Link;
export { PrimaryLink, ButtonLink, SmallLink, OptionalLink, FakeLink };
