import React from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import Image from "theme/components/atoms/Image";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import { OptionalLink } from "theme/components/atoms/Typography/Link/";

const Block504 = ({ isDesktop, data }) => {
  const vimeo = data?.vimeo_link ?? null;
  const vimeo_mobile = data?.vimeo_link_mobile ?? null;
  //https://player.vimeo.com/video/219652845?h=126e6cd5d6
  //mobile : https://player.vimeo.com/video/580755599?h=0eb20e5d36

  return data.image || vimeo ? (
    <div className="block-prismic block-504">
      <OptionalLink to={data.link}>
        {vimeo ? (
          <div className="video-ctn">
            <ReactPlayer
              url={!isDesktop && vimeo_mobile ? vimeo_mobile : vimeo}
              playing={true}
              playsinline={true}
              muted={true}
              loop={true}
              height="auto"
              width="auto"
            />
          </div>
        ) : data.image ? (
          <Image
            src={isDesktop ? data.image.url : data.image_mobile.url}
            alt={data.image.alt ?? ""}
            format="original"
          />
        ) : null}
      </OptionalLink>
    </div>
  ) : null;
};

export default compose(withMediaQueryProps)(Block504);
