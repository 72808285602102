import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import ProductItem from "theme/modules/ProductView/ProductItem";
import Link from "theme/components/atoms/Typography/Link";
import Carousel from "theme/components/organisms/Carousel/Carousel";
import PrismicHeading from "../PrismicHeading";

const Block514 = ({ data }) => {
  const products = data.products.filter((product) => product);
  if (!products.length) {
    return null;
  }

  return (
    <div className="block-prismic block-514">
      <div className="flex bottom-xxs px-2 px-md-4">
        <div className="block-514__title flex start-xxs my-3">
          {data.title ? (
            <PrismicHeading
              {...data.title}
              additionnalClasses={
                "block-514__title__heading" +
                (data.bigTitle ? " h1 block-514__title__heading--big" : " h5")
              }
            />
          ) : null}
          {data.bigTitle && data.labelNumber ? (
            <span className="block-514__title__number ml-3">
              {data.labelNumber}
            </span>
          ) : null}
        </div>
        <div className="my-3">
          {data.link ? (
            <Link to={data.link}>
              <span className="block-514__link">
                {data.label ? data.label : "Voir tout"}
              </span>
            </Link>
          ) : null}
        </div>
      </div>
      <div className="pl-2 pl-md-4 block-514__carousel">
        {products ? (
          <Carousel>
            {data.products.map((product, i) => {
              return product ? (
                <ProductItem
                  key={`514-${product.sku}${i}`}
                  product={product}
                  {...product}
                />
              ) : null;
            })}
          </Carousel>
        ) : null}
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block514);
