import React, { useEffect } from "react";
import { useRouteHasChanged } from "theme/components/helpers/router";

const Panel = ({
  title,
  children,
  footer,
  open,
  onClose,
  name = "default",
}) => {
  const hasChanged = useRouteHasChanged();
  useEffect(() => {
    if (open && hasChanged) {
      handleClosePanel();
    } else if (open) {
      document.documentElement.classList.add("html-panel--opened");
    } else {
      document.documentElement.classList.remove("html-panel--opened");
    }
  }, [open, hasChanged]);

  const handleClosePanel = () => {
    document.documentElement.classList.remove("html-panel--opened");
    onClose();
  };

  return (
    <div className={`panel ${open ? "panel--open" : ""} panel-${name}`}>
      <div className="panel__inner">
        <div className="panel__header">
          <div className="panel__header__title panel-title">{title}</div>
          <div onClick={handleClosePanel} className="panel__header__close">
            <i className="icon icon-close"></i>
          </div>
        </div>
        <div className="panel__content py-5">{children}</div>
        {footer ? <div className="panel__footer">{footer}</div> : null}
      </div>
      <div onClick={handleClosePanel} className="panel__overlay" />
    </div>
  );
};

export default Panel;
