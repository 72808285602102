import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";
import {
  OptionalLink,
  FakeLink,
} from "theme/components/atoms/Typography/Link/";
import Wysiwyg from "theme/modules/WysiwygV2";

const Block610 = ({ isDesktop, data }) => {
  return (
    <div className="block-prismic block-610">
      <div className="row">
        <div className="xxs12 xs6 md5 pr-md-3">
          {data.image1 ? (
            <Image
              src={isDesktop ? data.image1.url : data.image1_mobile.url}
              alt={data.image1.alt ?? data.prefixe}
              format="original"
            />
          ) : null}
        </div>
        <div className="xxs12 xs6 md7">
          <div className="flex start-xxs between-md nowrap fh col-xs">
            <div className="flex-md">
              {data.prefixe ? (
                <p className="h5 block-610__subtitle mr-3">{data.prefixe}</p>
              ) : null}
              {isDesktop && data.image2 ? (
                <Image
                  src={data.image2.url}
                  alt={data.image2.alt ?? data.prefixe}
                  format="original"
                />
              ) : null}
            </div>
            <div className="flex-md nowrap block-610__content">
              {data.titleWysiwyg ? (
                <OptionalLink to={data.link}>
                  <span className="block-610__title">
                    <Wysiwyg content={data.titleWysiwyg} />
                  </span>
                </OptionalLink>
              ) : null}
              <div className="block-610__desc flex-md col-md">
                <p className="text-medium">
                  <FakeLink to={data.link}>
                    {data.description}{" "}
                    <i className="mt-6 mt-xs-2 icon icon-arrow"></i>
                  </FakeLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block610);
