import React from "react";
import { compose, withState, withProps } from "recompose";
import { FormattedMessage, defineMessages } from "react-intl";
import Link from "theme/components/atoms/Typography/Link";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";

const messages = defineMessages({
  contactLinkLabel: {
    id: "modules.User.LoginForm.contactLinkLabel",
    defaultMessage: "Contactez-nous",
  },
})

const EnhanceLoginForm = ({ LoginMutation }) =>
  compose(
    withState("canSubmit", "setCanSubmit", false),
    withState("errorMessage", "setErrorMessage", null),
    withProps((props) => ({
      onSuccess: () => {
        props.onLoginSuccess();
      },
      onError: () => {
        props.setErrorMessage(
          <FormattedMessage
            id="modules.User.LoginForm.loginError"
            defaultMessage="Your credentials are incorrect or your account is temporarily disabled after {maxAttempts} login attempts."
            values={{
              maxAttempts: 6,
              contactLinkLabel: <Link appearance="underline" to={"/contact"}>{props.intl.formatMessage(messages.contactLinkLabel)}</Link>
            }}
          />
        );
      },
    })),
    makeCommandDispatcher({
      authenticateUser:
        (props) =>
        ({ email, password }) => {
          props.setErrorMessage(null);
          return {
            options: {
              mutation: LoginMutation,
              refetchQueries: ["User", "Cart", "CartQty"],
              variables: {
                username: email,
                password: password,
              },
            },
          };
        },
    })
  );

export default EnhanceLoginForm;
