import React from "react";
import ReactPlayer from "react-player";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Image from "theme/components/atoms/Image";
import {
  OptionalLink,
  FakeLink,
} from "theme/components/atoms/Typography/Link/";
import PrismicHeading from "../PrismicHeading";

const Block602Title = ({ title, link, reverse }) => {
  const additionnalClasses = `hero-${
    title.text?.length > 15 ? "m mt-2 mt-md-5" : "l"
  } block-602__title flex${link && !reverse ? " with-arrow" : ""}`;
  return (
    <PrismicHeading {...title} additionnalClasses={additionnalClasses}>
      <span className="block-602__title-text underline-animated">
        {title.text}
      </span>
    </PrismicHeading>
  );
};

const Block602 = ({ isTablet, isDesktop, data }) => {
  const vimeo = data?.vimeo_link ?? null;
  const vimeo_mobile = data?.vimeo_link_mobile ?? null;
  return data.image ? (
    <div className={`block-prismic block-602${data.reverse ? " reverse" : ""}`}>
      <div className=" block-602__img-wrapper">
        <FakeLink to={data.link}>
          {vimeo ? (
            <div className="video-ctn">
              <ReactPlayer
                url={
                  !isTablet && !isDesktop && vimeo_mobile ? vimeo_mobile : vimeo
                }
                playing={true}
                playsinline={true}
                muted={true}
                loop={true}
                height="auto"
                width="auto"
                controls={true}
              />
            </div>
          ) : (
            <Image
              src={
                isTablet || isDesktop ? data.image.url : data.image_mobile.url
              }
              alt={data.image.alt ?? data.title.text}
              format="original"
            />
          )}
        </FakeLink>
      </div>
      <div className="block-602__title-wrapper">
        <div className="row">
          <div className="xxs12 sm4">
            {data.prefixe && !data.reverse ? (
              <OptionalLink to={data.link}>
                <span className="h5">{data.prefixe}</span>
              </OptionalLink>
            ) : null}
          </div>
          <div className="xxs12 sm8">
            <OptionalLink to={data.link}>
              <Block602Title {...data} />
            </OptionalLink>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default compose(withMediaQueryProps)(Block602);
