module.exports = {
  fr_fr: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/fr_fr',
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "FR",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  fr_en: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/fr_en',
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "FR",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  fr_de: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/fr_de',
    locale: "de-DE",
    currency: "EUR",
    default_country_id: "FR",
    countries: (IsoCountries) =>
        IsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json")),
  },
  eu_fr: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/eu_fr',
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "DE",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  eu_en: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/eu_en',
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "DE",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  eu_de: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/eu_de',
    locale: "de-DE",
    currency: "EUR",
    default_country_id: "DE",
    countries: (IsoCountries) =>
        IsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json")),
  },
  int_fr: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/int_fr',
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "DZ",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  int_en: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/int_en',
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "DZ",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  int_de: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/int_de',
    locale: "de-DE",
    currency: "EUR",
    default_country_id: "DZ",
    countries: (IsoCountries) =>
        IsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json")),
  },
  no_fr: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/no_fr',
    locale: "fr-FR",
    currency: "EUR",
    default_country_id: "VE",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/fr.json")),
  },
  no_en: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/no_en',
    locale: "en-GB",
    currency: "EUR",
    default_country_id: "VE",
    countries: (IsoCountries) =>
      IsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json")),
  },
  no_de: {
    url: process.env.FRONT_COMMERCE_CLOUD_URL + '/no_de',
    locale: "de-DE",
    currency: "EUR",
    default_country_id: "VE",
    countries: (IsoCountries) =>
        IsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json")),
  },
};
