import React, { Fragment, cloneElement } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useFormRef } from "theme/components/atoms/Form/Form/Form";

const STATE_DISABLED = "disabled";
const STATE_PENDING = "pending";

const SubmitWrapper = ({ children, onClick }) => {
  const formRef = useFormRef();
  return cloneElement(children, {
    onClick: (event) => {
      if (onClick) {
        return onClick(event);
      } else {
        event.stopPropagation();
        event.preventDefault();
        formRef.current.submit(event);
      }
    },
  });
};

export const makeButtonClasses = (appearance, size = "default", state) =>
  classNames("button", {
    [`button--${appearance}`]: appearance,
    [`button--${size}`]: size,
    [`button--${state}`]: state,
  });

const Button = ({
  onClick,
  onDisableClick,
  children,
  appearance,
  icon,
  size,
  state,
  disabled,
  pending,
  dangerouslyIgnoreOnClick,
  type,
  title,
  additionnalClasses,
  ...rest
}) => {
  if (
    process.env.NODE_ENV !== "production" &&
    state === STATE_DISABLED &&
    type !== "submit" &&
    !onDisableClick
  ) {
    console.warn(
      "You should tell the user why the button is disabled if he still click on it",
      children
    );
  }

  if (
    process.env.NODE_ENV === "development" &&
    typeof onClick === "function" &&
    type === "submit"
  ) {
    console.warn("You can't use `onClick` on a submit button", children);
  }

  if (
    process.env.NODE_ENV === "development" &&
    state !== STATE_DISABLED &&
    typeof onClick !== "function" &&
    type !== "submit" &&
    !dangerouslyIgnoreOnClick
  ) {
    console.warn("You must use `onClick` on a button", children);
  }

  const shouldDisable = state === STATE_DISABLED || state === STATE_PENDING;
  const onClickBasedOnState = shouldDisable
    ? (e) => {
        e.preventDefault();
        onDisableClick && onDisableClick(e);
      }
    : onClick;

  const ButtonWrapper = type === "submit" ? SubmitWrapper : Fragment;
  const wrapperProps =
    type === "submit" ? { onClick: onClickBasedOnState } : {};

  return (
    <ButtonWrapper {...wrapperProps}>
      <button
        {...rest}
        onClick={onClickBasedOnState}
        className={`${additionnalClasses} ${makeButtonClasses(appearance, size, state)}`}
        type={type}
        title={title}
        disabled={shouldDisable && !onDisableClick}
      >
        {children}
      </button>
    </ButtonWrapper>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  onDisableClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["default", "mini", "small", "big", "full"]),
  appearance: PropTypes.oneOf([
    "unstyled",
    "default",
    "primary",
    "secondary",
    "ghost",
    "reverse",
    "link",
    "link-reverse",
    "warning",
    "icon",
    "icon-block",
    "icon-block-primary",
    "block",
    "ghost-disabled",
  ]),
  state: PropTypes.oneOf(["default", STATE_PENDING, STATE_DISABLED]),
  dangerouslyIgnoreOnClick: PropTypes.bool,
  type: PropTypes.string,
};

export default Button;
