import React from "react";
import Heading from "theme/components/atoms/Typography/Heading/Heading";

const PrismicHeading = ({ type, text, additionnalClasses, children }) => {
  const titleComponents = {
    heading1: Heading("h1", "H1", additionnalClasses),
    heading2: Heading("h2", "H2", additionnalClasses),
    heading3: Heading("h3", "H3", additionnalClasses),
    heading4: Heading("h4", "H4", additionnalClasses),
    heading5: Heading("h5", "H5", additionnalClasses),
    heading6: Heading("h6", "H6", additionnalClasses),
  };

  if (type !== "paragraph") {
    const Component = titleComponents[type];
    return Component ? (
      <Component additionnalClasses={additionnalClasses}>
        {children ?? text}
      </Component>
    ) : null;
  }

  return <span className={additionnalClasses}>{children ?? text}</span>;
};

export default PrismicHeading;
