import React from "react";
import { compose } from "recompose";
import withMediaQueryProps from "theme/components/helpers/withMediaQueryProps";
import Wysiwyg from "theme/modules/WysiwygV2";

const Block613 = ({ data }) => {
  return (
    <div className="block-prismic block-614 border-top pt-5 pt-md-8">
      <div className="row">
        <span className="xxs12 md5 h3 my-0">{data.quote ?? null}</span>
      </div>
      <div className="row">
        <div
          className={`offset-md9 md3 xxs12 p2${
            data.reverse ? " first-md" : ""
          } mt-4 mt-md-0`}
        >
          {data.descriptionWysiwyg ? (
            <Wysiwyg content={data.descriptionWysiwyg} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default compose(withMediaQueryProps)(Block613);
