import React from "react";
import { compose } from "recompose";
import config from "config/website";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import EnhanceRegisterForm from "./EnhanceRegisterForm";
import LoginMutation from "theme/modules/User/LoginForm/LoginMutation.gql";
import RegisterMutation from "./RegisterMutation.gql";
import {
  passwordValidationRules,
  passwordValidationErrors,
  passwordNotMatchingErrorMessage,
} from "theme/components/atoms/Form/Input/Password/passwordFieldValidator";
import Form from "theme/components/atoms/Form/Form";
import Fieldset from "theme/components/atoms/Form/Fieldset";
import {
  Text,
  Email,
  Password,
  PasswordStrengthHint,
  Hidden,
} from "theme/components/atoms/Form/Input";
import TitleSelect from "theme/components/atoms/Form/Input/TitleSelect";
import { Checkbox } from "theme/components/atoms/Form/Input";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import { ErrorAlert } from "theme/components/molecules/Alert";
import FormItem from "theme/components/molecules/Form/Item";
import Link from "theme/components/atoms/Typography/Link";

const messages = defineMessages({
  lastnameLabel: {
    id: "modules.User.RegisterForm.lastname",
    defaultMessage: "Lastname",
  },
  lastnameError: {
    id: "modules.User.RegisterForm.lastnameError",
    defaultMessage: "Please fill in your lastname",
  },
  firstnameLabel: {
    id: "modules.User.RegisterForm.firstname",
    defaultMessage: "Firstname",
  },
  firstnameError: {
    id: "modules.User.RegisterForm.firstnameError",
    defaultMessage: "Please fill in your firstname",
  },
  emailLabel: {
    id: "modules.User.RegisterForm.email",
    defaultMessage: "Email",
  },
  emailError: {
    id: "modules.User.RegisterForm.emailError",
    defaultMessage: "Please fill in a valid email",
  },
  passwordLabel: {
    id: "modules.User.RegisterForm.password",
    defaultMessage: "Password",
  },
  passwordConfirmationLabel: {
    id: "modules.User.RegisterForm.passwordConfirm",
    defaultMessage: "Password confirmation",
  },
  newsletterLabel: {
    id: "modules.User.RegisterForm.newsletter",
    defaultMessage: "Subscribe to our Newsletter",
  },
  titleError: {
    id: "modules.User.RegisterForm.titleError",
    defaultMessage: "Please choose your title",
  },
  titleLabel: {
    id: "modules.User.RegisterForm.title",
    defaultMessage: "Title",
  },
  legal1: {
    id: "modules.User.RegisterForm.legal1",
    defaultMessage: "Je déclare être âgé(e) d’au moins 15 ans",
  },
  legal2: {
    id: "modules.User.RegisterForm.legal2",
    defaultMessage:
      "Après avoir lu {policy}, j’autorise le traitement de mes données personnelles pour la création du compte.",
  },
  policy: {
    id: "modules.User.RegisterForm.policy",
    defaultMessage: "la politique de confidentialité",
  },
});

const RegisterForm = ({ initialValues, ...props }) => {
  return (
    <Form
      onChange={props.onChange}
      onValidSubmit={data => {
        props.setPending(true);
        props.register(data);
      }}
    >
      <Fieldset>
        {props.errorMessage && (
          <ErrorAlert key="error">{props.errorMessage}</ErrorAlert>
        )}
        {initialValues?.orderId && (
          <Hidden name="orderId" id="orderId" value={initialValues.orderId} />
        )}

        {config.useUserTitles && (
          <TitleSelect
            placeholder={props.intl.formatMessage(messages.titleLabel)}
            aria-label={props.intl.formatMessage(messages.titleLabel)}
            name="title"
            id="title"
            value={initialValues?.title}
          />
        )}
        <FormItem label={props.intl.formatMessage(messages.lastnameLabel)}>
          <Text
            name="lastname"
            id="lastname"
            required
            value={initialValues?.lastname}
            aria-label={props.intl.formatMessage(messages.lastnameLabel)}
            validationError={props.intl.formatMessage(messages.lastnameError)}
          />
        </FormItem>
        <FormItem label={props.intl.formatMessage(messages.firstnameLabel)}>
          <Text
            name="firstname"
            id="firstname"
            required
            value={initialValues?.firstname}
            aria-label={props.intl.formatMessage(messages.firstnameLabel)}
            validationError={props.intl.formatMessage(messages.firstnameError)}
          />
        </FormItem>
        <FormItem label={props.intl.formatMessage(messages.emailLabel)}>
          <Email
            name="email"
            id="email"
            required
            aria-label={props.intl.formatMessage(messages.emailLabel)}
            validationError={props.intl.formatMessage(messages.emailError)}
            autoComplete="username"
            value={props.value?.email ?? initialValues?.email}
          />
        </FormItem>
        <FormItem label={props.intl.formatMessage(messages.passwordLabel)}>
          <Password
            name="password"
            id="password"
            autoComplete="new-password"
            required
            aria-label={props.intl.formatMessage(messages.passwordLabel)}
            validations={passwordValidationRules()}
            validationError={passwordValidationErrors(props.intl, "HINTER")}
          />
        </FormItem>
        <PasswordStrengthHint formValuePath="password" />
        <FormItem
          label={props.intl.formatMessage(messages.passwordConfirmationLabel)}
        >
          <Password
            name="password_confirmation"
            id="password_confirmation"
            autoComplete="new-password"
            validations="equalsField:password"
            required
            aria-label={props.intl.formatMessage(
              messages.passwordConfirmationLabel
            )}
            validationError={props.intl.formatMessage(
              passwordNotMatchingErrorMessage
            )}
          />
        </FormItem>
        <div className="my-3 my-md-3 mt-6 mt-md-7">
          <Checkbox
            name="newsletter"
            id="newsletter"
            label={props.intl.formatMessage(messages.newsletterLabel)}
          />
        </div>
        <div className="my-3 my-md-3">
          <Checkbox
            name="legal1"
            id="legal1"
            required
            label={props.intl.formatMessage(messages.legal1)}
          />
        </div>
        <div className="my-3 my-md-3 mb-6 mb-md-7">
          <Checkbox
            name="legal2"
            id="legal2"
            required
            label={props.intl.formatMessage(messages.legal2, {
              policy: (
                <Link to={config.policyUrl}>
                  {props.intl.formatMessage(messages.policy)}
                </Link>
              ),
            })}
          />
        </div>
        <div className="">
          <div className="btn-ctn btn-fw mb-3">
            <SubmitButton
              type="submit"
              state={props.pending ? "pending" : undefined}
              appearance="primary"
            >
              <FormattedMessage
                id="modules.RegisterForm.create"
                defaultMessage="Create my account"
              />
            </SubmitButton>
          </div>
          {props.additionalActions}
        </div>
      </Fieldset>
    </Form>
  );
};

export default compose(
  EnhanceRegisterForm({ LoginMutation, RegisterMutation }),
  injectIntl
)(RegisterForm);
