// ajout d'un event sur les input pour les label animés (focus et blur)

import React, { createRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import BaseInput from "./BaseInput";
import withFormHandlers from "./withFormHandlers";
import compose from "recompose/compose";
import branch from "recompose/branch";
import Checkbox from "./Checkbox";
import NumberInput from "./NumberInput";

export { BaseInput };

const Input = (props) => {
  const {
    getErrors,
    isPristine,
    isValid,
    isDirty,
    appearance,
    inputSize,
    baseInputProps,
    help,
    autocomplete,
    autoComplete,
    ...rest
  } = props;

  const $input = createRef();

  useEffect(() => {
    rest.value &&
      $input.current?.closest(".form-item")?.classList.add("not-empty");
    if ($input.current?.id === "search") {
      $input.current.focus();
    }
  });

  if (props.type === "hidden") {
    return <input {...rest} />;
  }
  const classes = classNames({
    "input--invalid": getErrors().length,
    "input--valid": isValid(),
    [`input--${inputSize}`]: inputSize,
  });

  return (
    <BaseInput
      appearance={appearance}
      className={classes}
      input={
        <input
          className={classes}
          onFocus={(e) =>
            e.target.closest(".form-item").classList.add("not-empty")
          }
          onBlur={(e) =>
            e.target.closest(".form-item").classList.remove("not-empty")
          }
          autoComplete={autoComplete || autocomplete || "on"}
          {...rest}
          ref={$input}
        />
      }
      help={help}
      errors={getErrors()}
      {...baseInputProps}
    />
  );
};

Input.defaultProps = {
  appearance: "default",
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  appearance: BaseInput.propTypes.appearance,
  type: PropTypes.oneOf([
    "text",
    "email",
    "tel",
    "checkbox",
    "radio",
    "hidden",
    "password",
    "number",
  ]).isRequired,
  placeholder: PropTypes.string,
  inputSize: PropTypes.oneOf(["default", "short"]),
  baseInputProps: PropTypes.object,
};

export default compose(
  branch(
    (props) => props.type === "checkbox",
    () => Checkbox,
    branch(
      (props) => props.type === "number",
      () => NumberInput,
      (BaseComponent) => BaseComponent
    )
  ),
  withFormHandlers()
)(Input);
