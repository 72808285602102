import { defineMessages } from "react-intl";

const messages = defineMessages({
  tooWeak: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Status.tooWeak",
    defaultMessage: "Too weak",
  },
  weak: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Status.weak",
    defaultMessage: "Weak",
  },
  average: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Status.average",
    defaultMessage: "Average",
  },
  strong: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Status.strong",
    defaultMessage: "Strong",
  },
  tooShort: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Rules.tooShort",
    defaultMessage: "Too short",
  },
  lowercase: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Rules.lowercase",
    defaultMessage: "Lowercase",
  },
  uppercase: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Rules.uppercase",
    defaultMessage: "Uppercase",
  },
  specialchars: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Rules.specialchars",
    defaultMessage: "Special characters",
  },
  numbers: {
    id: "components.atoms.Form.Input.PasswordStrengthHint.Rules.numbers",
    defaultMessage: "Numerical character",
  },
});

export default {
  rules: [
    {
      id: "too short",
      label: messages.tooShort,
      isValid: password => Boolean(password.match(/^.{8,}$/)),
    },
    {
      id: "uppercase",
      label: messages.uppercase,
      isValid: password => Boolean(password.match(/[A-Z]/)),
    },
    // {
    //   id: "special",
    //   label: messages.specialchars,
    //   isValid: password => Boolean(password.match(/[^a-zA-Z0-9]/)),
    // },
    {
      id: "lowercase",
      label: messages.lowercase,
      isValid: password => Boolean(password.match(/[a-z]/)),
    },
    {
      id: "numbers",
      label: messages.numbers,
      isValid: password => Boolean(password.match(/[0-9]/)),
    },
  ],
  status: {
    TOO_SHORT: {
      label: messages.tooShort,
      status: "error",
      isValid: false,
    },
    TOO_WEAK: {
      label: messages.tooWeak,
      status: "error",
      minCriterias: 0,
      isValid: false,
    },
    WEAK: {
      label: messages.weak,
      status: "warning",
      minCriterias: 3,
      isValid: false,
    },
    AVERAGE: {
      label: messages.average,
      status: "success",
      minCriterias: 4,
      isValid: true,
    },
    STRONG: {
      label: messages.strong,
      status: "success",
      minCriterias: 5,
      isValid: true,
    },
  },
};
