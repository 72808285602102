import React, { useState } from "react";

const Accordion = ({
  title,
  children,
  isOpen,
  headerContent,
  headerStatus,
  visibleContent,
}) => {
  const [open, setOpen] = useState(isOpen ?? false);

  return (
    <div className={"accordion" + (open ? " accordion--open" : "")}>
      <div
        className="accordion__title row nowrap pb-3"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="xxs nogrow">
          <h6 className="h6 my-0">{title}</h6>
        </div>
        {headerContent && !headerStatus && <div className="xxs">{headerContent}</div>}
        {headerStatus && headerContent && <div className="xxs end-xxs"><span className={`label-status mr-md-7 ${headerStatus === 'disabled'?"label-status--disabled":""} `}>{headerContent}</span></div>}
        <div className="xxs nogrow">
          <i className={`icon icon-${open ? "minus" : "plus"}`} />
        </div>
      </div>
      {visibleContent && (
        <div className="accordion__visible-content pb-5">{visibleContent}</div>
      )}
      <div className="accordion__content row">
        <div className="xxs12">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
