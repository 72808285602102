import Block501, { Block501Fragment } from "./Block501";
import Block502, { Block502Fragment } from "./Block502";
import Block503, { Block503Fragment } from "./Block503";
import Block504, { Block504Fragment } from "./Block504";
import Block510, { Block510Fragment } from "./Block510";
import Block511, { Block511Fragment } from "./Block511";
import Block512, { Block512Fragment } from "./Block512";
import Block513, { Block513Fragment } from "./Block513";
import Block514, { Block514Fragment } from "./Block514";
import Block515, { Block515Fragment } from "./Block515";
import Block601, { Block601Fragment } from "./Block601";
import Block602, { Block602Fragment } from "./Block602";
import Block603, { Block603Fragment } from "./Block603";
import Block604, { Block604Fragment } from "./Block604";
import Block610, { Block610Fragment } from "./Block610";
import Block611, { Block611Fragment } from "./Block611";
import Block612, { Block612Fragment } from "./Block612";
import Block613, { Block613Fragment } from "./Block613";
import Block614, { Block614Fragment } from "./Block614";
import Block620, { Block620Fragment } from "./Block620";

import makeSliceLibrary from "theme/modules/Prismic/Slices/makeSliceLibrary";

const sliceLibrary = makeSliceLibrary("PrismicLandingSlice", [
  { component: Block501, fragment: Block501Fragment },
  { component: Block502, fragment: Block502Fragment },
  { component: Block503, fragment: Block503Fragment },
  { component: Block504, fragment: Block504Fragment },
  { component: Block510, fragment: Block510Fragment },
  { component: Block511, fragment: Block511Fragment },
  { component: Block512, fragment: Block512Fragment },
  { component: Block513, fragment: Block513Fragment },
  { component: Block514, fragment: Block514Fragment },
  { component: Block515, fragment: Block515Fragment },
  { component: Block601, fragment: Block601Fragment },
  { component: Block602, fragment: Block602Fragment },
  { component: Block603, fragment: Block603Fragment },
  { component: Block604, fragment: Block604Fragment },
  { component: Block610, fragment: Block610Fragment },
  { component: Block611, fragment: Block611Fragment },
  { component: Block612, fragment: Block612Fragment },
  { component: Block613, fragment: Block613Fragment },
  { component: Block614, fragment: Block614Fragment },
  { component: Block620, fragment: Block620Fragment },
]);

const definitions = sliceLibrary.fragmentDefinitions;
export { definitions };

export default sliceLibrary.SliceZone;
