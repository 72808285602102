import React from "react";
import useFullUrl from "web/core/shop/useFullUrl";

const Logo = (props) => {
  const path = !props.light ? "/images/Logo.svg" : "/images/Logo-light.svg";
  const url = useFullUrl(path);
  return (
    <div className="logo-ctn">
      <img src={url} alt="Logo" className="logo" width="143" height="11" />
    </div>
  );
};

export default Logo;
