import React from "react";

const PageHeading = ({ type, title, children }) => {
  return (
    <div className={`page-heading page-heading__${type ?? "simple"}`}>
      <div className="page-heading__title">
        <h1 className="h2">{title}</h1>
      </div>
      {children ? (
        <div className="page-heading__content">{children}</div>
      ) : null}
    </div>
  );
};

export const PageHeadingBg = ({ type, title, children }) => {
  return (
    <div className={`page-heading page-heading__${type ?? "bg"} row`}>
      <div className="xxs12 offset-md4 md7">
        <div className="page-heading__inner">
          <div className="page-heading__title ">
            <h1 className="h2">{title}</h1>
          </div>
          {children ? (
            <div className="page-heading__content">{children}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PageHeading;
