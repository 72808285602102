import React from "react";
import { useHistory } from "react-router";

const FakeLink = ({ to, children }) => {
  const history = useHistory();

  if (!to) {
    return <>{children}</>;
  }

  return (
    <span className="cursor" onClick={() => history.push(to)}>
      {children}
    </span>
  );
};

export default FakeLink;
