import React from "react";
import compose from "recompose/compose";
import { withRouter } from "react-router";
import LoadingArea from "theme/components/molecules/LoadingArea";
import Link from "theme/components/atoms/Typography/Link";
import Wysiwyg from "theme/modules/WysiwygV2";
import { TrackPage } from "theme/modules/Analytics/withTrackPage";
import LinkListSelector from "theme/components/molecules/LinkListSelector";
import useMediaQueryProps from "theme/components/helpers/useMediaQueryProps";
import { PageHeadingBg } from "theme/components/molecules/PageHeading";
import LandingSliceZone from "theme/modules/Prismic/Slices";

import { Helmet } from "react-helmet-async";
import { defineMessages, injectIntl } from "react-intl";

const messages = defineMessages({
  metaTitle: {
    id: "pages.Seo.metaTitle",
    defaultMessage: "Jérôme Dreyfuss - Official Store",
  },
});
const Cms = ({ loading, matched, intl }) => {
  const { isDesktop } = useMediaQueryProps();

  const routes = [];
  if (matched !== undefined) {
    const { siblings } = matched;
    for (let sibling in siblings) {
      routes.push({
        value: siblings[sibling].slug,
        label: siblings[sibling].title,
      });
    }
  } else {
    var siblings = null;
  }

  return loading ? (
    <LoadingArea></LoadingArea>
  ) : (
    <>
      <Helmet>
        <title>
          {matched.title} {`|`} {intl.formatMessage(messages.metaTitle)}
        </title>
      </Helmet>

      <TrackPage title={matched.title} />
      {matched?.slices?.length ? (
        <LandingSliceZone content={matched.slices} />
      ) : (
        <div className="cms__wrapper p1">
          <PageHeadingBg title={matched.title} type="bg">
            {!isDesktop ? (
              <div className="mb-3">
                <LinkListSelector appearance="navigation" paths={routes} />
              </div>
            ) : null}
          </PageHeadingBg>
          <div className="cms__details row start-xxs">
            <div className="cms__siblings xxs12 md4">
              {isDesktop ? (
                <ul>
                  {siblings
                    ? siblings.map((sibling, i) => (
                        <li
                          key={`sibling${i}`}
                          className={
                            sibling.slug === matched.slug ? "active" : ""
                          }
                        >
                          <Link to={sibling.slug}>{sibling.title}</Link>
                        </li>
                      ))
                    : null}
                </ul>
              ) : null}
            </div>
            <div className="cms__content xxs12 offset-sm2 sm8 offset-md0 md5">
              {matched.description ? (
                <div className="wysiwyg">
                  <Wysiwyg content={matched.description} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default compose(withRouter, injectIntl)(Cms);
