// antadis : chge pixel grey in pixel white
import React from "react";
import classnames from "classnames";

export const PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=";
export const ERROR = "/images/resized/error.png";

export const transparentExtensions = ["webp", "png"];
export const extensions = ["webp", "jpeg", "gif"];
export const defaultExtension = "jpeg";

const Picture = ({ src, sources, alt, sizes, placeholder, ...props }) => {
  const placeholderSizes = placeholder ? { width: "1", height: "1" } : null;
  return (
    <picture>
      {sources &&
        sources.map((sourceProps) => (
          <source key={sourceProps.type} {...sourceProps} sizes={sizes} />
        ))}
      <img {...placeholderSizes} src={src} alt={alt} {...props} />
    </picture>
  );
};

const ImageComponent = React.forwardRef(
  (
    {
      images,
      alt,
      sizes,
      appearance,
      cover,
      imgProps,
      ratio,
      status,
      dangerouslyDisableLazyLoad,
    },
    ref
  ) => {
    const classes = classnames("responsive-image", {
      [`responsive-image--${appearance}`]: appearance,
      [`responsive-image--done`]: status === "done",
      [`responsive-image--error`]: status === "error",
      [`responsive-image--loading`]: status === "loading" || status === "lazy",
      "responsive-image--cover": cover,
    });

    return (
      <div
        ref={ref}
        className={classes}
        style={
          ratio
            ? { "--image-ratio": `${(ratio * 100).toFixed(0)}%` }
            : undefined
        }
      >
        <Picture
          {...imgProps}
          {...images[status === "loading" ? "done" : status]}
          className="responsive-image__actual"
          alt={alt}
          sizes={sizes}
        />
        {!dangerouslyDisableLazyLoad && (
          <Picture
            {...images.loading}
            className="responsive-image__placeholder"
            alt={status === "loading" ? "Loading" : ""}
            sizes={sizes}
            placeholder={true}
          />
        )}
      </div>
    );
  }
);

export default ImageComponent;
