import { useRef } from "react";
import { useLocation } from "react-router-dom";

const useRouteHasChanged = () => {
  const currentLocation = useLocation().pathname;
  const savedLocation = useRef(currentLocation);

  const hasChanged = currentLocation !== savedLocation.current;

  if (hasChanged) {
    savedLocation.current = currentLocation;
  }

  return hasChanged;
};

export default useRouteHasChanged;
